import { Link, Typography } from "@mui/material";

// TODO: Load content from firebase and use something to parse input from markdown to html
const AboutInfo = () => {
  return (
    <>
      <Typography color="primary" variant="h5" sx={{ mb: 2 }}>
        Bienvenue
      </Typography>
      <Typography variant="body1">
        Le Dico API est un dictionnaire illustré et inclusif en ligne avec
        entrée phonétique et orthographe rectifiée pour favoriser la
        compréhension et l'expression écrite et limiter la double tâche.
      </Typography>
      <Typography variant="body1">
        L'application fournit 3 niveaux d'aide (Un curseur sur le côté avec des
        boutons "-" et "+" ) :
      </Typography>
      <Typography variant="body1">
        <em>Orthographique :</em> j'écris en respectant l'orthographe (le début
        du mot doit être juste)
      </Typography>
      <Typography variant="body1">
        <em>Phonétique :</em> j'écris en respectant les sons (avec de l'aide
        pour les détails)
      </Typography>
      <Typography variant="body1">
        <em>Phonétique élargie :</em> j'écris comme je pense
      </Typography>
      <Typography mt={1} variant="body1">
        Cliquer pour davantage d'informations{" "}
        <Link
          href="https://apiceras.ch/listeoutils/dico-apiceras/"
          target="_blank"
        >
          https://apiceras.ch/listeoutils/dico-apiceras/
        </Link>
      </Typography>
      <Typography mt={1} variant="body1">
        <em>Appel à contribution :</em> Vous pouvez participer à l'amélioration
        du dico en cliquant sur le point d'interrogation et en nous indiquant
        (ou pas) votre suggestion de correction.
      </Typography>
      <Typography variant="h6" color="primary" sx={{ mt: 2 }}>
        Conseil PRATIQUE
      </Typography>
      <Typography variant="body1">
        Pour les devoirs à la maison. Créer un raccourci sur votre bureau
        d'ordinateur ou tablette et dans votre téléphone.
      </Typography>
      <Typography variant="body1">
        Sur Android, vous pouvez installer l'application.
      </Typography>
      <Typography variant="h6" color="primary" sx={{ mt: 2 }}>
        Concept et gestion du projet :
      </Typography>
      <Typography>Luca Ferrazzini et André Tissot-Daguette</Typography>
      <Typography variant="h6" color="primary" sx={{ mt: 2 }}>
        Programmation :
      </Typography>
      <Typography>
        Joachim Huet, Cyriaque Skrapits, Pierre-Alain Etique et Paul Tissot
      </Typography>
      <Typography variant="h6" color="primary" sx={{ mt: 2 }}>
        Images :
      </Typography>
      <Typography>
        ARASAAC sous Licence Creative Commons BY-NC-SA, autorisant leur
        utilisation à des fins à but non lucratif en indiquant la source et
        l'auteur, et sont partagées sous la même licence. Par conséquent,
        l'utilisation de ces ressources dans n'importe quel produit ou
        publication à des fins commerciales est exclue.
      </Typography>
      <Typography variant="h6" color="primary" sx={{ mt: 2 }}>
        Définitions :
      </Typography>
      <Typography>
        <Link href="https://fr.wiktionary.org/wiki/Wiktionnaire:Page_d%E2%80%99accueil">
          Wiktionnaire
        </Link>{" "}
        sous "Licence de documentation libre GNU"
      </Typography>
      <Typography variant="h6" color="primary" sx={{ mt: 2 }}>
        Licence et droits :
      </Typography>
      <Typography>
        Le Dico API est un projet de L’API-L, association suisse à but
        non-lucratif de développement d’aides pédagogiques libres. Pour des
        demandes d'utilisation ou de citations dans d’autres interfaces,
        matériel scolaire ou projets divers, veuillez-vous en informer sur{" "}
        <Link target="blank" href="https://lapil.ch/">
          LAPI_L
        </Link>{" "}
        ou en vous adressant à{" "}
        <Link target="blank" href="mailto:contact@lapil.ch">
          contacATlapil.ch
        </Link>
      </Typography>
      <Typography>La Chaux-de-Fonds 2024</Typography>
    </>
  );
};

export default AboutInfo;
