import { Box, Link, Typography } from "@mui/material";

import androidImage from "../assets/dl-android.png";

const Footer = () => {
  return (
    <Box sx={{ m: 3 }} textAlign="center">
      <Typography variant="body1" component="div" sx={{ mb: 2 }}>
        Un outil de{" "}
        <Link
          fontWeight="bold"
          color="primary"
          target="_blank"
          href="https://lapil.ch"
        >
          L'APIL
        </Link>
      </Typography>
      <Typography variant="body2">
        Téléchargez l'application android:
      </Typography>
      <Link href="https://apiceras.ch/dico/" target="_blank">
        <Box
          component="img"
          src={androidImage}
          height={50}
          alt="Télécharger l'application android"
        />
      </Link>
    </Box>
  );
};

export default Footer;
